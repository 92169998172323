// import from "../textures/tie_and_dye/21 tie and dye.jpg";
import AllServices from "../../../../Services/usersService";

import batik2 from "../textures/tie_and_dye/7. bubu.jpg";
import batik3 from "../textures/tie_and_dye/8. tomogyi.jpg";
import batik4 from "../textures/tie_and_dye/9. gye nyame.jpg";
import batik5 from "../textures/tie_and_dye/10 medofo.jpg";

import nailDesign3 from "../textures/nail designs/22.jpg";
import nailDesign4 from "../textures/nail designs/23.jpg";
// import nailDesign13 from "../textures/nail designs/18.jpg";
import nailDesign30 from "../textures/nail designs/edwed.jpg";
import nailDesign31 from "../textures/nail designs/ef.jpg";
import nailDesign37 from "../textures/nail designs/fwef.jpg";
import nailDesign38 from "../textures/nail designs/geg.jpg";
import nailDesign41 from "../textures/nail designs/rege.jpg";

//news
import nailDesign43 from "../textures/nail designs/new/2.jpg";
import nailDesign44 from "../textures/nail designs/new/2ed2d.jpg";
import nailDesign45 from "../textures/nail designs/new/4.jpg";
import nailDesign47 from "../textures/nail designs/new/fwef.jpg";

import waxPrint1 from "../textures/waxPrint/new/1 dade3 mpadua.jpg";
import waxPrint2 from "../textures/waxPrint/new/2. ayeyi wura.jpg";
import waxPrint3 from "../textures/waxPrint/new/3. 8 8.jpg";
import waxPrint4 from "../textures/waxPrint/new/4. A B C.jpg";
import waxPrint5 from "../textures/waxPrint/new/5. Apaawa.jpg";
import waxPrint6 from "../textures/waxPrint/new/6. Sika nto nkwa.jpg";

// disapora
import conti from "../textures/diaspora/conti_LE_auto_x2.jpg";
import katanga from "../textures/diaspora/kat_LE_auto_x2.jpg";
import republic from "../textures/diaspora/repo_LE_auto_x2.jpg";
import queens from "../textures/diaspora/A.png";
import africa from "../textures/diaspora/africa_LE_auto_x2.jpg";


//commemerative
import otumfuo from "../textures/commemorative/otumfuo.jpg";
import asantenhene25thaniversary from "../textures/commemorative/1-imageonline.co-merged.jpeg"
import asantenhene30thanniversary from "../textures/commemorative/gfsdg-imageonline.co-merged (1).jpeg"

//new newTextures
import n1 from "../textures/new_textures/1. ahwene pa nkasa.jpg";
import n2 from "../textures/new_textures/2. ahwenepa nkasa.jpg";
import n3 from "../textures/new_textures/3. akuffo.jpg";
import n4 from "../textures/new_textures/4. akyemfr3.jpg";
import n5 from "../textures/new_textures/5. akyikyidie akyi.jpg";
import n6 from "../textures/new_textures/6. animonyam nka nyame.jpg";
import n7 from "../textures/new_textures/7. ghana flag - Copy.jpg";
// import n7 from "../textures/new_textures/7. ghana flag.jpg";
import n8 from "../textures/new_textures/8. kotodwe.jpg";
import n9 from "../textures/new_textures/9. nkitinkiti.jpg";
import n10 from "../textures/new_textures/10. nsuanhunu.jpg";
import n11 from "../textures/new_textures/11. obaa pa.jpg";
import n12 from "../textures/new_textures/12. obaapa.jpg";
import n13 from "../textures/new_textures/13. obi de3 aba.jpg";
import n14 from "../textures/new_textures/14. oya suro oya.jpg";
import n15 from "../textures/new_textures/15. sakadom.jpg";
import n16 from "../textures/new_textures/16. samarama k3t3.jpg";
import n17 from "../textures/new_textures/17. sika wo ntaban.jpg";
import n18 from "../textures/new_textures/18. subra.jpg";
import n19 from "../textures/new_textures/19 ab3n.jpg";
import n20 from "../textures/new_textures/20 abusua mu wo abusua kronkron.jpg";
import n21 from "../textures/new_textures/21 adeakye 3bia.jpg";
import n22 from "../textures/new_textures/22 adekye3 mu nsem.jpg";
import n23 from "../textures/new_textures/23 adom arakwa.jpg";
import n24 from "../textures/new_textures/24 akoraa bo nwa.jpg";
import n25 from "../textures/new_textures/25 ankonam moboro.jpg";
import n26 from "../textures/new_textures/26 aseda wo oyame ho.jpg";
import n27 from "../textures/new_textures/27 boafo ne awurade.jpg";
import n28 from "../textures/new_textures/28 boafoo ni bebiara.jpg";
import n29 from "../textures/new_textures/29 daakye asem nti.jpg";
import n30 from "../textures/new_textures/30 flower.jpg";
import n31 from "../textures/new_textures/31 obaapa.jpg";
import n32 from "../textures/new_textures/32 sape.jpg";
import n33 from "../textures/new_textures/33 senkyi bridge.jpg";
import n34 from "../textures/new_textures/34 y3da awurade ase.jpg";
import n35 from "../textures/new_textures/35 yesu mogya.jpg";

// logos
import logo1 from "../textures/logos/AFRO_LOGO_4_transparent.png";
import logo2 from "../textures/logos/gye-nyame-medium.png";
import logo3 from "../textures/logos/1 gye nyame.-removebg-preview.png";
import logo4 from "../textures/logos/2_eban_-removebg-preview.png";
import logo5 from "../textures/logos/3._duafe-removebg-preview.png";
import logo6 from "../textures/logos/4._Nelson-Mandela-Silhouette-edit-online-removebg-preview.png";
import logo7 from "../textures/logos/5._Heart_with_Vibrant_Flames-removebg-preview.png";
import logo8 from "../textures/logos/6._Shooting_Star-removebg-preview.png";
import logo9 from "../textures/logos/7._Dove_with_Olive_Branch-removebg-preview.png";
import logo10 from "../textures/logos/8 infinity_loop_with_floral_twist-removebg-preview.png";
import logo11 from "../textures/logos/9. baobab_tree-removebg-preview.png";
import logo12 from "../textures/logos/10._Lion_Crowned-removebg-preview.png";
import logo13 from "../textures/logos/11_Sun_Over_Mountains-removebg-preview.png";
import logo14 from "../textures/logos/12_Mandala_Sunburst-removebg-preview.png";
import logo15 from "../textures/logos/13._Palm_Tree_with_Sunset_Silhouette-removebg-preview.png";
import logo16 from "../textures/logos/14_Music_Notes_with_Vibrant_Splash-removebg-preview.png";
import logo17 from "../textures/logos/15_African_Continent_with_Compass-removebg-preview.png";
import logo18 from "../textures/logos/16._It_always_seems_impossible_until_it_s_done.-removebg-preview.png";
import logo19 from "../textures/logos/17._The_power_of_the_people_is_stronger_than_the_people_in_power-removebg-preview.png";
import logo20 from "../textures/logos/18_We_can_change_the_world_and_make_it_a_better_place._It_is_in_your_hands_to_make_a_difference.-removebg-preview.png";
import logo21 from "../textures/logos/19_No_condition_is_permanent._-removebg-preview.png";
import logo22 from "../textures/logos/20_Blessings_on_blessings-removebg-preview.png";
import logo23 from "../textures/logos/21_Dream_big__start_small__but_most_of_all__start.-removebg-preview.png";
import logo24 from "../textures/logos/22_Until_the_lion_learns_to_write__every_story_will_glorify_the_hunter.-removebg-preview.png";
import logo25 from "../textures/logos/23_Education_is_the_most_powerful_weapon_which_you_can_use_to_change_the_world.-removebg-preview.png";
import logo26 from "../textures/logos/24_If_you_educate_a_man__you_educate_an_individual._But_if_you_educate_a_woman__you_educate_a_nation-removebg-preview.png";
import logo27 from "../textures/logos/25_My_culture__my_pride-removebg-preview.png";
// import logo28 from "../textures/logos/26 good_vibe__great_tribe-removebg-preview.png";
import logo29 from "../textures/logos/27_I_will_plant_a_tree.-removebg-preview.png";
// import logo30 from "../textures/logos/28..unstoppable-removebg-preview.png";
// import logo31 from "../textures/logos/29 my_environment__my_responsibility-removebg-preview.png";
import logo32 from "../textures/logos/30_I_am_because_we_are-removebg-preview.png";
import logo33 from "../textures/logos/31_nelson-removebg-preview.png";
import logo34 from "../textures/logos/32_kwame_nkrumah-removebg-preview.png";
// import logo35 from "../textures/logos/33_Wangari_Maathai-removebg-preview.png";
import logo36 from "../textures/logos/34_Desmond_Tutu-removebg-preview.png";
import logo37 from "../textures/logos/35_Chinua_Achebe-removebg-preview.png";
import logo38 from "../textures/logos/36_Thomas_Sankara-removebg-preview.png";
import logo39 from "../textures/logos/37_Miriam_Makeba-removebg-preview.png";
import logo40 from "../textures/logos/39_Fela_Kuti-removebg-preview.png";
import logo41 from "../textures/logos/40_Haile_Selassie-removebg-preview.png";
import logo42 from "../textures/logos/41_Dr._James_Kwegyir_Aggrey-removebg-preview.png";
import logo43 from "../textures/logos/42_Yaa_Asantewaa-removebg-preview.png";
import logo44 from "../textures/logos/43_Aliko_Dangote-removebg-preview.png";
import logo45 from "../textures/logos/44_Patrice-Lumumba-removebg-preview.png";
import logo46 from "../textures/logos/45_kofi_annan-removebg-preview.png";
import FabricPrices from "../../../../Data/fabricprices";








// const waxPrint1 = "https://firebasestorage.googleapis.com/v0/b/shopinafrica-c84cf.appspot.com/o/texture%2F1%20dade3%20mpadua.jpg?alt=media&token=d7fb314a-7d6a-477a-acce-fabfb2d2cfd5"


export const colorBasePrice = 35;

export const colorOptions = [
  { color: "#ff0000", label: "Red" },
  { color: "#ffffff", label: "White" },
  { color: "#00ff00", label: "Green" },
  { color: "#ffd700", label: "Gold" },
  { color: "#0000ff", label: "Blue" },
  { color: "#222222", label: "Black" },
  { color: "#666666", label: "Grey" },
  { color: "#444444", label: "Lighter Black" },
  { color: "#ffff00", label: "Yellow" },
  { color: "#87ceeb", label: "Seablue" },
  { color: "#ff7f50", label: "Coral" },
  { color: "#008080", label: "Teal" },
  { color: "#808000", label: "Olive" },
  { color: "#e0b0ff", label: "Mauve" },
  { color: "#c0c0c0", label: "Silver" },
  { color: "#ffa500", label: "Orange" },
  { color: "#800080", label: "Purple" },
  { color: "#ff69b4", label: "Pink" },
  { color: "#a52a2a", label: "Brown" },
  { color: "#808080", label: "Gray" },
  { color: "#00ffff", label: "Cyan" },
  { color: "#ff00ff", label: "Magenta" },
  { color: "#8a2be2", label: "Blue Violet" },
  { color: "#deb887", label: "Burly Wood" },
  { color: "#5f9ea0", label: "Cadet Blue" },
  { color: "#7fff00", label: "Chartreuse" },
  { color: "#d2691e", label: "Chocolate" },
  { color: "#ff7f50", label: "Coral" },
  { color: "#6495ed", label: "Cornflower Blue" },
  { color: "#dc143c", label: "Crimson" },
  { color: "#00ffff", label: "Cyan" },
  { color: "#00008b", label: "Dark Blue" },
  { color: "#008b8b", label: "Dark Cyan" },
  { color: "#b8860b", label: "Dark Golden Rod" },
  { color: "#a9a9a9", label: "Dark Gray" },
  { color: "#006400", label: "Dark Green" },
  { color: "#bdb76b", label: "Dark Khaki" },
  { color: "#8b008b", label: "Dark Magenta" },
  { color: "#556b2f", label: "Dark Olive Green" },
  { color: "#ff8c00", label: "Dark Orange" },
  { color: "#9932cc", label: "Dark Orchid" },
  { color: "#8b0000", label: "Dark Red" },
  { color: "#e9967a", label: "Dark Salmon" },
  { color: "#8fbc8f", label: "Dark Sea Green" },
  { color: "#483d8b", label: "Dark Slate Blue" },
  { color: "#2f4f4f", label: "Dark Slate Gray" },
  { color: "#00ced1", label: "Dark Turquoise" },
  { color: "#9400d3", label: "Dark Violet" },
  { color: "#ff1493", label: "Deep Pink" },
  { color: "#00bfff", label: "Deep Sky Blue" },
  { color: "#696969", label: "Dim Gray" },
  { color: "#1e90ff", label: "Dodger Blue" },
  { color: "#b22222", label: "Fire Brick" },
  { color: "#ff4500", label: "Orange Red" },
];

export const specialNodeNames = [
  "button",
  "buttons",
  "sole",
  "zippers",
  "brass",
  "cuttings",
  // "nails",
  "nailHands",
]; // Add your special node names here

let returnedDecsriptions = [];

const initializeData = async () => {
  try {
    const response = await AllServices.getAllFabrics();
    const allFabrics = response.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    returnedDecsriptions = allFabrics.reduce((acc, fabric) => {
      const { id, items } = fabric;
      acc[id] = items.map((item) => ({
        textureName: item.textureName || "",
        textureDescription: item.textureDescription || "",
      }));
      return acc;
    }, {});
  } catch (error) {
    console.error("Failed to fetch fabrics", error);
  }
};

initializeData();

export const textureArrays = {
  batik: [batik2, batik3, batik4, batik5],

  waxPrint: [n1, n2, n3, n4, n5, n6, n7, n8, n9, n10, n11, n12, n13, n14, n15, n16, n17, n18, n19, n20, n21, n22, n23, n24, n25, n26, n27, n28, n29, n30, n31, n32, n33, n34, n35,waxPrint1, waxPrint2, waxPrint3, waxPrint4, waxPrint5, waxPrint6, otumfuo, asantenhene30thanniversary],
  Diaspora: [conti, katanga, republic, queens, africa],
  nailDesigns: [
    nailDesign3,
    nailDesign4,
    nailDesign30,
    nailDesign31,
    nailDesign37,
    nailDesign38,
    nailDesign41,
    nailDesign43,
    nailDesign44,
    nailDesign45,
    nailDesign47,
  ],
  // commemorative: [otumfuo, asantenhene30thanniversary],
  newTextures: [],
  // newTextures: [n1, n2, n3, n4, n5, n6, n7, n8, n9, n10, n11, n12, n13, n14, n15, n16, n17, n18, n19, n20, n21, n22, n23, n24, n25, n26, n27, n28, n29, n30, n31, n32, n33, n34, n35],
  logos : [logo1, logo2, logo4, logo6, logo8, logo9, logo12, logo13, logo14, logo15, logo17, logo18, logo20, logo21, logo22, logo23, logo24, logo26, logo27, logo29, logo32]
};

export const textureDescriptions = {
  batik: [
    {
      textureName: "Bubu",
      textureDescription: `Step out in style and embrace the vibrant culture of Africa with the stunning bubu fabric! Known
      for its intricate patterns and rich history, bubu fabric is a true representation of African heritage
      and creativity. So why not add a touch of vibrant flair to your wardrobe and inspire others to do
      the same by proudly wearing bubu fabric? Join the movement and let your fashion do the
      talking with bubu!`,
    },

    {
      textureName: "Tomogyi",
      textureDescription: `Wrap yourself in the vibrant colors and rich cultural history of Africa with the eye-catching
      tomogyi fabric. This fabric is not just a piece of cloth- it is a work of art that tells a story about
      the beauty and diversity of the African continent. Embrace the sense of empowerment and
      connection that comes with wearing tomogyi, and celebrate the uniqueness of African heritage
      in style. Let the bold patterns and exquisite craftsmanship of tomogyi be your signature look and
      make a statement wherever you go. Dare to stand out and be proud to wear the African
      fabric, tomogyi.`,
    },

    {
      textureName: "Gye Nyame",
      textureDescription: `Have you heard of the ancient African fabric called Gye Nyame, which means except God.
      Embrace the rich history and cultural significance of this beautiful fabric by incorporating it into
      your wardrobe. Let its powerful message remind you to always put your faith in something
      greater. Wear gye nyame proudly and experience the unique and vibrant energy it
      brings to your style.`,
    },
    {
      textureName: "Medofo",
      textureDescription: `Wrap yourself in a piece of culture and love with the beautifully vibrant African fabric known as medofo,
      which translates to "my love" in English. Embrace the rich history and tradition woven into each thread,
      and proudly make a statement with your unique style. Let medofo be a symbol of appreciation for
      African artistry and a celebration of unity through fashion. So why wait? Let your wardrobe reflect love
      and diversity by wearing medofo today!`,
    },
  ],

  waxPrint: [
    {
      "textureName": "Ahwene Pa Nkasa (Good Things Don’t Speak for Themselves)",
      "textureDescription": "This fabric symbolizes quiet excellence, showing that true value does not boast. Wear it proudly to represent confidence, knowing your worth will shine through your actions.",
    },
    {
      "textureName": "Ahwenepa Nkasa (Good Things Don’t Speak for Themselves)",
      "textureDescription": "This design reflects silent greatness and high standards. Rock this fabric with pride as a reminder that excellence needs no announcement—it simply is.",
    },
    {
      "textureName": "Akuffo",
      "textureDescription": "This fabric embodies boldness and leadership. Wear it to make a statement about courage, determination, and standing tall in every situation.",
    },
    {
      "textureName": "Akyemfrɛ (You Can’t Call Me Twice)",
      "textureDescription": "Representing uniqueness, this pattern tells the world you are one of a kind. Wear it to celebrate your individuality and self-worth.",
    },
    {
      "textureName": "Akyikyideɛ Akyi (Back of the Tortoise)",
      "textureDescription": "A symbol of resilience and hidden strength. Put on this fabric to show that you can overcome life’s challenges with patience and fortitude.",
    },
    {
      "textureName": "Animuonyam Nka Nyame (All Glory Belongs to God)",
      "textureDescription": "Perfect for expressing gratitude and humility. Wear it to celebrate your blessings and acknowledge God’s hand in your life.",
    },
    {
      "textureName": "Ghana Flag",
      "textureDescription": "Celebrate unity, freedom, and patriotism with this design. Wear it with pride to show your love for Ghana at national events or every day.",
    },
    {
      "textureName": "Kotodwe (Chicken Leg)",
      "textureDescription": "Symbolizing unexpected power in small things. Step out in this fabric to express strength, even in the smallest of beginnings.",
    },
    {
      "textureName": "Nkitinkiti (Intricate Patterns)",
      "textureDescription": "Representing creativity and complexity, this fabric is perfect for innovators. Wear it boldly to embrace your ability to think differently.",
    },
    {
      "textureName": "Nsuanhunu (Foam on Water)",
      "textureDescription": "A symbol of elegance and subtle influence. Dress in this fabric to show that even the lightest touch can leave a lasting impact.",
    },
    {
      "textureName": "Obaa Pa (Virtuous Woman)",
      "textureDescription": "Celebrate the strength and grace of women with this design. Wear it to embody integrity, care, and the beauty of womanhood."
    },
    {
      "textureName": "Obi Deɛ Aba (What Someone Brings)",
      "textureDescription": "This fabric teaches gratitude. Wear it proudly as a reminder to appreciate the gifts and kindness of others."
    },
    {
      "textureName": "Ɔya Suro Ɔya (Pain Fears Pain)",
      "textureDescription": "A message of resilience, encouraging you to overcome challenges. Rock this fabric to express that you’ve faced hardships and emerged stronger."
    },
    {
      "textureName": "Sakadom (Freedom)",
      "textureDescription": "Wear this fabric to embrace your freedom and individuality. It’s perfect for breaking boundaries and expressing your true self."
    },
    {
      "textureName": "Samrama Ketɛ (Woven Mat)",
      "textureDescription": "This pattern celebrates traditional craftsmanship and unity. Wear it with pride to honor cultural heritage and simplicity."
    },
    {
      "textureName": "Sika Wo Ntaban (Money Has Wings)",
      "textureDescription": "A fabric reminding us to manage wealth wisely. Dress in it to express mindfulness about your financial journey."
    },
    {
      "textureName": "Subra (Morality)",
      "textureDescription": "Showcase your character and discipline by wearing this fabric. It’s ideal for occasions celebrating responsibility and values."
    },
    {
      "textureName": "Abɛn (Horn)",
      "textureDescription": "Symbolizing a call to action, wear this fabric to show leadership and inspire others to follow your example."
    },
    {
      "textureName": "Abusua Mu Wo Abusua Kronkron (There’s a Sacred Family Within a Family)",
      "textureDescription": "This fabric celebrates family unity and legacy. Wear it with pride for family reunions or events honoring heritage."
    },
    {
      "textureName": "Adeakyeɛ Bia (Every Day is a Gift)",
      "textureDescription": "Celebrate life by wearing this fabric. It’s perfect for brightening your day and spreading joy to others."
    },
    {
      "textureName": "Adeakyeɛ Bia (Every Day is a Gift)",
      "textureDescription": "Celebrate life by wearing this fabric. It’s perfect for brightening your day and spreading joy to others."
    },
    {
      "textureName": "Adekyeɛ Munsem (Morning’s Secrets)",
      "textureDescription": "Ideal for fresh starts, this fabric reminds you that every new day brings opportunity. Wear it when embracing change and new beginnings."
    },
    {
      "textureName": "Adom Arakwa (Graceful Robe)",
      "textureDescription": "Wear this elegant design to express grace, beauty, and poise at any gathering."
    },
    {
      "textureName": "Akoraa Bɔ Nwa (Little One Catches the Big One)",
      "textureDescription": "A fabric that encourages perseverance. Wear it to remind yourself and others that small efforts can achieve great results."
    },
    {
      "textureName": "Ankonam Mmoboro (The Lonely One Finds Comfort)",
      "textureDescription": "Perfect for expressing personal strength and inner peace. Wear it as a symbol of resilience in solitude."
    },
    {
      "textureName": "Aseda Wo Nyame Ho (Gratitude to God)",
      "textureDescription": "A great choice for religious events, this fabric reflects gratitude for life’s blessings. Wear it to express thankfulness."
    },
    {
      "textureName": "Boafoɔ Ne Awurade (God is the Helper)",
      "textureDescription": "Wear this to show your faith in divine guidance. It’s ideal for spiritual occasions or as a reminder of God’s help in your life."
    },
    {
      "textureName": "Boafoɔ Ni Bebia (Helper in Every Place)",
      "textureDescription": "This fabric inspires trust in finding help everywhere. Wear it to remind yourself to seek support when needed."
    },
    {
      "textureName": "Daakye Asem Nti (For the Sake of the Future)",
      "textureDescription": "Perfect for goal-setters, wear this fabric to express ambition and a vision for a brighter future."
    },
    {
      "textureName": "Flower",
      "textureDescription": "Inspired by nature, this pattern promotes joy and love. Wear it to spread cheer and positivity wherever you go."
    },
    {
      "textureName": "Obaapa (Good Woman)",
      "textureDescription": "Celebrate impactful women with this design. Wear it to inspire others with your kindness and achievements."
    },
    {
      "textureName": "Sape (Wisdom)",
      "textureDescription": "A great fabric for scholars and thinkers. Wear it to show your love for learning and wisdom."
    },
    {
      "textureName": "Senkyi Bridge",
      "textureDescription": "This fabric celebrates connection and unity. Wear it to promote collaboration and building bridges between people."
    },
    {
      "textureName": "Yɛda Awurade Ase (We Thank the Lord)",
      "textureDescription": "Wear this to express gratitude for life’s blessings. It’s perfect for celebrations and spiritual events."
    },
    {
      "textureName": "Yesu Mogya (Blood of Jesus)",
      "textureDescription": "A powerful symbol of faith and sacrifice. Wear this fabric proudly to express your Christian beliefs and devotion."
    },
    {
      textureName: "DADE3 MPADUA",
      textureDescription: `Experience the vibrancy and elegance of African culture with the stunning dade3 mpadua fabric.
      Embodying the rich heritage and craftsmanship of African textiles, this fabric will make you
      stand out in style. So why not elevate your wardrobe and celebrate the beauty of African
      fashion by wearing dade3 mpadua?`,
    },
    {
      textureName: "Ayeyi Wura",
      textureDescription: `Wear the vibrant Ayeyi Wura fabric and praise the living God with every step you take. This
      African fabric is a symbol of gratitude and celebration, making it perfect for brightening up any
      outfit. Embrace the rich cultural heritage and unique beauty of Ayeyi Wura, and let your style
      speak volumes. Join the movement and showcase the essence of African fashion with this fabric
      that exudes positivity and faith.`,
    },
    {
      textureName: "8 8",
      textureDescription: `Embrace the vibrant and cultural essence of the African fabric known as 8 8, symbolizing the
      interconnectedness of life. Let your style speak volumes as you don this unique and colorful
      fabric, encouraging a celebration of diversity and heritage. Stand out from the crowd and make
      a statement by incorporating this beautiful fabric into your wardrobe today. Experience the
      beauty of Africa and express yourself through the art of fabric. Wear 8 8 and bring a touch of
      culture and tradition into your everyday life.`,
    },
    {
      textureName: "A B C",
      textureDescription: `Wrap yourself in the vibrant colors and rich history of African fabric, known as ‘A B C’. Embrace
      the cultural significance and beauty of this unique textile as you make a statement with your
      wardrobe. Stand out from the crowd and show your appreciation for African craftsmanship by
      incorporating this stunning fabric into your everyday style. Wear the name of fabric is called A B C
      proudly and celebrate the beauty of African culture.`,
    },
    {
      textureName: "APAAWA",
      textureDescription: `Wrap yourself in the vibrant and rich history of African culture with the exquisite APAAWA
      fabric. This iconic textile encapsulates the craftsmanship and artistry of African artisans, telling a
      story of tradition and heritage with every thread. Embrace your individuality and make a bold
      statement by adorning yourself in the stunning patterns and colors of the APAAWA fabric.
      Celebrate diversity and creativity by incorporating this unique fabric into your wardrobe, and let
      your style speak volumes about your appreciation for the beauty of African craftsmanship. Try
      on the APAAWA fabric today and experience the magic of African fashion firsthand.`,
    },
    {
      textureName: "Sika Nto Nkwa",
      textureDescription: `Introducing the vibrant and meaningful African fabric called Sika Nto Nkwa, which translates to
     "money can't buy life". This beautiful fabric not only adds a touch of culture to your wardrobe,
      but also reminds you to cherish the moments that truly matter. So, why not embrace the rich
      heritage and unique design of African fabrics by incorporating Sika Nto Nkwa into your everyday
      style? Stand out from the crowd and wear a piece of tradition with pride!`,
    },

    {
      textureName: "Otumfuo Commemorative Fabric (1999-2019)",
      textureDescription: `This fabric honors 20 years of Otumfuo Osei Tutu II’s reign (1999-2019) with the theme "Deepening Our Cultural Heritage Through Socio-Economic Development." It symbolizes Otumfuo's dedication to preserving Ashanti culture while promoting progress.`,
      disclaimer: "Afroloom only sources this fabric from official outlets and does not produce it. In case of unavailability, a full refund will be provided.",
      footNote: "Wear this fabric to celebrate Otumfuo’s legacy of leadership, cultural pride, and development."
    },
    {
      textureName: "Asantehene’s 25th Anniversary Cloth",
      textureDescription: `Celebrate the 25th anniversary of Otumfuo Osei Tutu II with this commemorative fabric, designed to honor his reign and the rich cultural heritage of the Ashanti Kingdom. The intricate patterns reflect the depth of Ashanti traditions, symbolizing strength, unity, and leadership. `,
      disclaimer: " Please note, we only source these fabrics from official producers, and Afroloom does not manufacture them. Fabric availability depends on stock from the original producers.  ",
    },
    {
      textureName: "Asantehene’s 25th Anniversary Fabric",
      textureDescription: `Celebrate the 25th anniversary of Otumfuo Osei Tutu II with this commemorative fabric, designed to honor his reign and the rich cultural heritage of the Ashanti Kingdom. The intricate patterns reflect the depth of Ashanti traditions, symbolizing strength, unity, and leadership`,
      disclaimer: " Please note, we only source these fabrics from official producers, and Afroloom does not manufacture them. Fabric availability depends on stock from the original producers.  ",
    },
  ],
  diaspora: [
    {
      textureName: "Unity Hall Fabric (KNUST)",
      textureDescription: `Established in 1968, Unity Hall, also known as Conti, is one of the largest halls at KNUST and a pillar of leadership, unity, and strength. The first hall master was Dr. J.E. Arkorful. The bold and vibrant Unity Hall fabric reflects these long-standing traditions.`,
      disclaimer:
        "Please note that Afroloom only sources this fabric directly from Unity Hall, and we do not produce it. If the school runs out of stock, we will issue a full refund",
    },
    {
      textureName: "Katanga Hall Fabric (KNUST)",
      textureDescription: `Built in 1963, Katanga Hall, officially known as University Hall, has long been a symbol of bravery, camaraderie, and resilience. The first hall master was Mr. J.B. Furlong, whose legacy still echoes in the hall's bold traditions. Now a mixed hall, Katanga continues to stand tall in KNUST’s history.`,
      disclaimer: "Please note that Afroloom only sources this fabric directly from Katanga Hall, and we do not produce it. If the school runs out of stock, we will issue a full refund",
    },
    {
      textureName: "Republic Hall Fabric (KNUST)",
      textureDescription: `Established in 1961, Republic Hall represents diversity, inclusivity, and academic excellence. Known for fostering a vibrant community, Republic Hall has made a lasting impact on the KNUST campus. The first hall master was Dr. R.P. Baffour, a visionary leader.`,
      disclaimer: "Please note that Afroloom only sources this fabric directly from Republic Hall, and we do not produce it. If the school runs out of stock, we will issue a full refund",
    },
    {
      textureName: "Queen Elizabeth Hall Fabric (KNUST)",
      textureDescription: `Built in 1959 and originally an all-female hall, Queen Elizabeth Hall is now a mixed hall known for its legacy of nurturing leadership and academic prowess. The first hall master was Mr. R.C. Rattray, who oversaw the hall’s initial development. The Queen’s Hall fabric captures this proud heritage.`,
      disclaimer: "Please note that Afroloom only sources this fabric directly from Queen Elizabeth Hall, and we do not produce it. If the school runs out of stock, we will issue a full refund",
    },
    {
      textureName: "Africa Hall Fabric (KNUST)",
      textureDescription: `Opened in 1967, Africa Hall, famously known as the Ladies Hall, has been a pillar of female empowerment and academic excellence at KNUST. The first hall master was Mrs. A. Gaisie, who guided the hall in its early days of building a legacy of leadership. The Africa Hall fabric celebrates this tradition of excellence.`,
      disclaimer: "Please note that Afroloom only sources this fabric directly from Africa Hall, and we do not produce it. If the school runs out of stock, we will issue a full refund",
    },
  ],
  // commemorative:[
  //   {
  //     textureName: "Otumfuo Commemorative Fabric (1999-2019)",
  //     textureDescription: `This fabric honors 20 years of Otumfuo Osei Tutu II’s reign (1999-2019) with the theme "Deepening Our Cultural Heritage Through Socio-Economic Development." It symbolizes Otumfuo's dedication to preserving Ashanti culture while promoting progress.`,
  //     disclaimer: "Afroloom only sources this fabric from official outlets and does not produce it. In case of unavailability, a full refund will be provided.",
  //     footNote: "Wear this fabric to celebrate Otumfuo’s legacy of leadership, cultural pride, and development."
  //   },
  //   {
  //     textureName: "Asantehene’s 25th Anniversary Cloth",
  //     textureDescription: `Celebrate the 25th anniversary of Otumfuo Osei Tutu II with this commemorative fabric, designed to honor his reign and the rich cultural heritage of the Ashanti Kingdom. The intricate patterns reflect the depth of Ashanti traditions, symbolizing strength, unity, and leadership. `,
  //     disclaimer: " Please note, we only source these fabrics from official producers, and Afroloom does not manufacture them. Fabric availability depends on stock from the original producers.  ",
  //   },
  //   {
  //     textureName: "Asantehene’s 25th Anniversary Fabric",
  //     textureDescription: `Celebrate the 25th anniversary of Otumfuo Osei Tutu II with this commemorative fabric, designed to honor his reign and the rich cultural heritage of the Ashanti Kingdom. The intricate patterns reflect the depth of Ashanti traditions, symbolizing strength, unity, and leadership`,
  //     disclaimer: " Please note, we only source these fabrics from official producers, and Afroloom does not manufacture them. Fabric availability depends on stock from the original producers.  ",
  //   },
   
  // ]
  newTextures: [
    {
      "textureName": "Ahwene Pa Nkasa (Good Things Don’t Speak for Themselves)",
      "textureDescription": "This fabric symbolizes quiet excellence, showing that true value does not boast. Wear it proudly to represent confidence, knowing your worth will shine through your actions.",
    },
    {
      "textureName": "Ahwenepa Nkasa (Good Things Don’t Speak for Themselves)",
      "textureDescription": "This design reflects silent greatness and high standards. Rock this fabric with pride as a reminder that excellence needs no announcement—it simply is.",
    },
    {
      "textureName": "Akuffo",
      "textureDescription": "This fabric embodies boldness and leadership. Wear it to make a statement about courage, determination, and standing tall in every situation.",
    },
    {
      "textureName": "Akyemfrɛ (You Can’t Call Me Twice)",
      "textureDescription": "Representing uniqueness, this pattern tells the world you are one of a kind. Wear it to celebrate your individuality and self-worth.",
    },
    {
      "textureName": "Akyikyideɛ Akyi (Back of the Tortoise)",
      "textureDescription": "A symbol of resilience and hidden strength. Put on this fabric to show that you can overcome life’s challenges with patience and fortitude.",
    },
    {
      "textureName": "Animuonyam Nka Nyame (All Glory Belongs to God)",
      "textureDescription": "Perfect for expressing gratitude and humility. Wear it to celebrate your blessings and acknowledge God’s hand in your life.",
    },
    {
      "textureName": "Ghana Flag",
      "textureDescription": "Celebrate unity, freedom, and patriotism with this design. Wear it with pride to show your love for Ghana at national events or every day.",
    },
    {
      "textureName": "Kotodwe (Chicken Leg)",
      "textureDescription": "Symbolizing unexpected power in small things. Step out in this fabric to express strength, even in the smallest of beginnings.",
    },
    {
      "textureName": "Nkitinkiti (Intricate Patterns)",
      "textureDescription": "Representing creativity and complexity, this fabric is perfect for innovators. Wear it boldly to embrace your ability to think differently.",
    },
    {
      "textureName": "Nsuanhunu (Foam on Water)",
      "textureDescription": "A symbol of elegance and subtle influence. Dress in this fabric to show that even the lightest touch can leave a lasting impact.",
    },
    {
      "textureName": "Obaa Pa (Virtuous Woman)",
      "textureDescription": "Celebrate the strength and grace of women with this design. Wear it to embody integrity, care, and the beauty of womanhood."
    },
    {
      "textureName": "Obi Deɛ Aba (What Someone Brings)",
      "textureDescription": "This fabric teaches gratitude. Wear it proudly as a reminder to appreciate the gifts and kindness of others."
    },
    {
      "textureName": "Ɔya Suro Ɔya (Pain Fears Pain)",
      "textureDescription": "A message of resilience, encouraging you to overcome challenges. Rock this fabric to express that you’ve faced hardships and emerged stronger."
    },
    {
      "textureName": "Sakadom (Freedom)",
      "textureDescription": "Wear this fabric to embrace your freedom and individuality. It’s perfect for breaking boundaries and expressing your true self."
    },
    {
      "textureName": "Samrama Ketɛ (Woven Mat)",
      "textureDescription": "This pattern celebrates traditional craftsmanship and unity. Wear it with pride to honor cultural heritage and simplicity."
    },
    {
      "textureName": "Sika Wo Ntaban (Money Has Wings)",
      "textureDescription": "A fabric reminding us to manage wealth wisely. Dress in it to express mindfulness about your financial journey."
    },
    {
      "textureName": "Subra (Morality)",
      "textureDescription": "Showcase your character and discipline by wearing this fabric. It’s ideal for occasions celebrating responsibility and values."
    },
    {
      "textureName": "Abɛn (Horn)",
      "textureDescription": "Symbolizing a call to action, wear this fabric to show leadership and inspire others to follow your example."
    },
    {
      "textureName": "Abusua Mu Wo Abusua Kronkron (There’s a Sacred Family Within a Family)",
      "textureDescription": "This fabric celebrates family unity and legacy. Wear it with pride for family reunions or events honoring heritage."
    },
    {
      "textureName": "Adeakyeɛ Bia (Every Day is a Gift)",
      "textureDescription": "Celebrate life by wearing this fabric. It’s perfect for brightening your day and spreading joy to others."
    },
    {
      "textureName": "Adeakyeɛ Bia (Every Day is a Gift)",
      "textureDescription": "Celebrate life by wearing this fabric. It’s perfect for brightening your day and spreading joy to others."
    },
    {
      "textureName": "Adekyeɛ Munsem (Morning’s Secrets)",
      "textureDescription": "Ideal for fresh starts, this fabric reminds you that every new day brings opportunity. Wear it when embracing change and new beginnings."
    },
    {
      "textureName": "Adom Arakwa (Graceful Robe)",
      "textureDescription": "Wear this elegant design to express grace, beauty, and poise at any gathering."
    },
    {
      "textureName": "Akoraa Bɔ Nwa (Little One Catches the Big One)",
      "textureDescription": "A fabric that encourages perseverance. Wear it to remind yourself and others that small efforts can achieve great results."
    },
    {
      "textureName": "Ankonam Mmoboro (The Lonely One Finds Comfort)",
      "textureDescription": "Perfect for expressing personal strength and inner peace. Wear it as a symbol of resilience in solitude."
    },
    {
      "textureName": "Aseda Wo Nyame Ho (Gratitude to God)",
      "textureDescription": "A great choice for religious events, this fabric reflects gratitude for life’s blessings. Wear it to express thankfulness."
    },
    {
      "textureName": "Boafoɔ Ne Awurade (God is the Helper)",
      "textureDescription": "Wear this to show your faith in divine guidance. It’s ideal for spiritual occasions or as a reminder of God’s help in your life."
    },
    {
      "textureName": "Boafoɔ Ni Bebia (Helper in Every Place)",
      "textureDescription": "This fabric inspires trust in finding help everywhere. Wear it to remind yourself to seek support when needed."
    },
    {
      "textureName": "Daakye Asem Nti (For the Sake of the Future)",
      "textureDescription": "Perfect for goal-setters, wear this fabric to express ambition and a vision for a brighter future."
    },
    {
      "textureName": "Flower",
      "textureDescription": "Inspired by nature, this pattern promotes joy and love. Wear it to spread cheer and positivity wherever you go."
    },
    {
      "textureName": "Obaapa (Good Woman)",
      "textureDescription": "Celebrate impactful women with this design. Wear it to inspire others with your kindness and achievements."
    },
    {
      "textureName": "Sape (Wisdom)",
      "textureDescription": "A great fabric for scholars and thinkers. Wear it to show your love for learning and wisdom."
    },
    {
      "textureName": "Senkyi Bridge",
      "textureDescription": "This fabric celebrates connection and unity. Wear it to promote collaboration and building bridges between people."
    },
    {
      "textureName": "Yɛda Awurade Ase (We Thank the Lord)",
      "textureDescription": "Wear this to express gratitude for life’s blessings. It’s perfect for celebrations and spiritual events."
    },
    {
      "textureName": "Yesu Mogya (Blood of Jesus)",
      "textureDescription": "A powerful symbol of faith and sacrifice. Wear this fabric proudly to express your Christian beliefs and devotion."
    }
   
  ],
  logos: [
    {
      "textureName": "Afroloom Logo",
      "textureDescription": "The official logo of the Afroloom company, representing the brand's dedication to celebrating African heritage and craftsmanship. The design symbolizes creativity, tradition, and a modern approach to showcasing textiles and culture."
    },
    {
      "textureName": "Gye Nyame (Adinkra Symbol)",
      "textureDescription": `&quot;Except God.&quot; This powerful Ghanaian symbol represents the supremacy of God, making
      it perfect for those who find strength in their faith.`    },
    // {
    //   "textureName": "Gye Nyame (Adinkra Symbol)",
    //   "textureDescription": `&quot;Except God.&quot; This powerful Ghanaian symbol represents the supremacy of God, making
    //     it perfect for those who find strength in their faith.`
    // },
    {
      "textureName": "Eban (Fence)",
      "textureDescription": `Symbol of safety and security. This Adinkra symbol resonates with love and the feeling of
        being protected, ideal for family-themed designs.`
    },
    // {
    //   "textureName": "Duafe (Wooden Comb)",
    //   "textureDescription": "Feminine virtues and self-care. This Adinkra symbol embodies cleanliness, love, and care, celebrating African women’s resilience and beauty."
    // },
    {
      "textureName": "Nelson Mandela’s Silhouette",
      "textureDescription": "The silhouette of the global icon symbolizes peace, justice, and perseverance, inspiring individuals to embrace leadership and change."
    },
    // {
    //   "textureName": "Heart with Vibrant Flames",
    //   "textureDescription": "A bold design representing passion and energy, it’s perfect for lovers, dreamers, and those who wear their hearts on their sleeves."
    // },
    {
      "textureName": "Shooting Star",
      "textureDescription": "Signifying limitless potential, this symbol is for dreamers and achievers striving to make their mark on the world."
    },
    {
      "textureName": "Dove with Olive Branch",
      "textureDescription": "A universal emblem of peace, paired with the legacy of Desmond Tutu, this symbol encourages harmony and forgiveness."
    },
    // {
    //   "textureName": "Infinity Loop with Floral Twist",
    //   "textureDescription": "Representing eternal love and growth, this design embodies hope and progress, inspired by Africa’s vibrant natural beauty."
    // },
    // {
    //   "textureName": "Baobab Tree (African Tree of Life)",
    //   "textureDescription": "A majestic symbol of strength, heritage, and community, deeply rooted in African traditions and values."
    // },
    {
      "textureName": "Lion Crowned",
      "textureDescription": "Representing courage and leadership, this design draws inspiration from the fearless Shaka Zulu, embodying strength and pride."
    },
    {
      "textureName": "Sun Over Mountains",
      "textureDescription": "A symbol of hope and new beginnings, honoring the environmental legacy of Wangari Maathai."
    },
    {
      "textureName": "Mandala Sunburst",
      "textureDescription": "Spiritual awakening and harmony come alive in this intricate design, perfect for deep thinkers and artistic souls."
    },
    {
      "textureName": "Palm Tree with Sunset Silhouette",
      "textureDescription": "Representing relaxation and the tropical African lifestyle, this design inspires serenity and peace."
    },
    // {
    //   "textureName": "Music Notes with Vibrant Splash",
    //   "textureDescription": "Celebrating the legacy of Fela Kuti, this design symbolizes creativity, rhythm, and the soul of African music."
    // },
    {
      "textureName": "African Continent with Compass",
      "textureDescription": "A symbol of exploration and pride, this design celebrates the richness of African identity, inspired by Chinua Achebe’s literary impact."
    },
    {
      "textureName": "Nelson Mandela Quote",
      "textureDescription": "It always seems impossible until it’s done. A call to perseverance, this quote motivates individuals to overcome challenges and achieve greatness."
    },
    // {
    //   "textureName": "Thomas Sankara Quote",
    //   "textureDescription": "The power of the people is stronger than the people in power. For the bold and revolutionary, this quote inspires collective action and empowerment."
    // },
    {
      "textureName": "Nelson Mandela Quote",
      "textureDescription": "We can change the world and make it a better place. It is in your hands to make a difference. A timeless reminder to take responsibility for creating a better future."
    },
    {
      "textureName": "African Proverb",
      "textureDescription": "No condition is permanent. An African proverb that instills hope and resilience, reminding us that change is always possible."
    },
    {
      "textureName": "Blessings on Blessings",
      "textureDescription": "A simple yet powerful affirmation of gratitude and abundance."
    },
    {
      "textureName": "Aliko Dangote Quote",
      "textureDescription": "Dream big, start small, but most of all, start. Inspired by Africa’s leading entrepreneur, this quote motivates individuals to turn ideas into reality."
    },
    {
      "textureName": "Chinua Achebe Quote",
      "textureDescription": "Until the lion learns to write, every story will glorify the hunter. A call for self-representation, celebrating African storytelling and authenticity."
    },
    // {
    //   "textureName": "Nelson Mandela Quote",
    //   "textureDescription": "Education is the most powerful weapon which you can use to change the world. For students and lifelong learners, this quote underscores the transformative power of knowledge."
    // },
    {
      "textureName": "Dr. James Kwegyir Aggrey Quote",
      "textureDescription": "If you educate a man, you educate an individual. But if you educate a woman, you educate a nation. Highlighting the importance of women’s education for societal progress."
    },
    {
      "textureName": "My Culture, My Pride",
      "textureDescription": "A celebration of African identity and heritage, inspiring individuals to embrace their roots."
    },
    // {
    //   "textureName": "Good Vibes, Great Tribe",
    //   "textureDescription": "A cheerful reminder of positivity and the power of community."
    // },
    {
      "textureName": "Wangari Maathai Quote",
      "textureDescription": "I will plant a tree. Emphasizing sustainability and growth, this quote inspires environmental action."
    },
    // {
    //   "textureName": "Unstoppable, Undeniable",
    //   "textureDescription": "A mantra for resilience and determination, perfect for go-getters."
    // },
    // {
    //   "textureName": "Wangari Maathai Quote",
    //   "textureDescription": "The environment is my responsibility. Encouraging individuals to take ownership of their ecological impact."
    // },
    {
      "textureName": "Ubuntu Philosophy",
      "textureDescription": "I am because we are. Celebrating the interconnectedness of humanity and the essence of community."
    },
    // {
    //   "textureName": "Nelson Mandela",
    //   "textureDescription": "A global symbol of leadership, resilience, and justice, inspiring individuals to strive for equality."
    // },
    // {
    //   "textureName": "Kwame Nkrumah",
    //   "textureDescription": "Advocate of African unity and independence, representing visionary leadership."
    // },
    // {
    //   "textureName": "Wangari Maathai",
    //   "textureDescription": "Nobel laureate and environmental activist, inspiring sustainability and growth."
    // },
    // {
    //   "textureName": "Desmond Tutu",
    //   "textureDescription": "Champion of peace and equality, advocating for forgiveness and reconciliation."
    // },
    // {
    //   "textureName": "Chinua Achebe",
    //   "textureDescription": "Renowned novelist, embodying the power of storytelling and African heritage."
    // },
    // {
    //   "textureName": "Thomas Sankara",
    //   "textureDescription": "Revolutionary leader and icon of social justice, inspiring bold change."
    // },
    // {
    //   "textureName": "Miriam Makeba",
    //   "textureDescription": "Mama Africa, celebrated for her music and activism."
    // },
    // {
    //   "textureName": "Shaka Zulu",
    //   "textureDescription": "Legendary warrior and king, symbolizing strength and leadership."
    // },
    // {
    //   "textureName": "Fela Kuti",
    //   "textureDescription": "Afrobeat pioneer and social activist, representing creativity and resistance."
    // },
    // {
    //   "textureName": "Haile Selassie",
    //   "textureDescription": "Ethiopian emperor and symbol of African pride and sovereignty."
    // },
    // {
    //   "textureName": "Dr. James Kwegyir Aggrey",
    //   "textureDescription": "Advocate of education, emphasizing progress through learning."
    // },
    // {
    //   "textureName": "Yaa Asantewaa",
    //   "textureDescription": "A fearless leader and symbol of courage and resistance."
    // },
    // {
    //   "textureName": "Aliko Dangote",
    //   "textureDescription": "Africa’s leading entrepreneur, inspiring innovation and hard work."
    // },
    // {
    //   "textureName": "Patrice Lumumba",
    //   "textureDescription": "Fighter for African independence and a symbol of liberation."
    // },
    // {
    //   "textureName": "Kofi Annan",
    //   "textureDescription": "Diplomat and former UN Secretary-General, embodying diplomacy and global leadership."
    // }
  ]

}



export const textureValues = {
  batik: {
    price: 50,
    yardStart: 1,
  },
  waxPrint: {
    price: 60,
    yardStart: 2,
  },
  Diaspora: {
    price: 130,
    yardStart: 2,
  },
  commemorative: {
    price: 130,
    yardStart: 2,
  },
  newTextures: {
    price: 60,
    yardStart: 2,
  },
  logos: {
    price: 1,
    yardStart: 2,
  }
};

export const sizeOptions = [
  { label: "S", value: 0.5 },
  { label: "M", value: 1 },
  { label: "L", value: 2 },
  { label: "XL", value: 3 },
  { label: "2XL", value: 4 },
];

export const europeanShoeSizes = [
  { label: "34", value: 34 },
  { label: "35", value: 35 },
  { label: "36", value: 36 },
  { label: "37", value: 37 },
  { label: "38", value: 38 },
  { label: "39", value: 39 },
  { label: "40", value: 40 },
  { label: "41", value: 41 },
  { label: "42", value: 42 },
  { label: "43", value: 43 },
  { label: "44", value: 44 },
  { label: "45", value: 45 },
  { label: "46", value: 46 },
  { label: "47", value: 47 },
];

export const responsiveColor = [
  {
    breakpoint: "1024px",
    numVisible: 3,
    numScroll: 1,
  },
  {
    breakpoint: "768px",
    numVisible: 2,
    numScroll: 1,
  },
  {
    breakpoint: "576px",
    numVisible: 1,
    numScroll: 1,
  },
];

export const displayInplaceFor = [
  "Trousers",
  "Shorts",
  "Summer Shorts",
  "Booty Shorts",
  "Leggings",
  "Long Sleeve Top And Down Kaftan",
  "Earring",
  "Beads Bracelet",
  "Kimono Jacket",
  "Mini Skirt",
  "Long Skirt",
];

export const noSpinFor = [
  "Brass Name Beads Bracelet Variant One",
  "Logo Up, Text Down Sash",
  "Text Up, Logo Down Sash",
  "Contrasting Logo and Text Sash",
  "Text Up, Image Down Sash",
  "One-Sided Logo, Two-Sided Text Sash",
  "Brass Name Beads Bracelet Variant Two",
];

export const onlySashes = [
  "Logo Up, Text Down Sash",
  "Text Up, Logo Down Sash",
  "Contrasting Logo and Text Sash",
  "Text Up, Image Down Sash",
  "One-Sided Logo, Two-Sided Text Sash",
];

export const notAll = ["Earring", "Bikini", "Square Nails", "Coffin Nails"];

export const hideNotAllNodes = ["bikini_all", "balls", "bikini_top"];

export const shiny3Ds = ["Beads Bracelet", "Earring"];
